<template>
	<div></div>
</template>

<script>
import { retrieveAccessRights } from '../composables/accessRights.comp.js'
import { useSelectedPlan } from '@/stores/plans/selectedPlan.js'
import { retrievePatientData } from '@/composables/patientData.comp.js'

export default {
	name: 'Five9ChatWidget',
	data() {
		return {
			options: {
				appId: 'abcbs',
				domain: 'abcbs',
				campaignName: import.meta.env.VITE_CHAT_CAMPAIGN ?? 'CHATBOT - Member claim status',
				subtitle: 'Blueprint Portal Chat',
				title: 'CHATBOT - Member claim status',
				waitingImage: 'https://live-chat.ps.five9.com/waiting-blue.gif',
				showEndButton: true,
				showButtons: true,
				endConfirmation: true,
				launcherOpenText: 'Chat',
				titleBackgroundColor: '#015987',
				welcomeTextColor: '#2778bc',
				agentTextColor: '#2778bc',
				customerBubbleColor: '#2778bc',
				templateButtonColor: '#96cff0',
				templateButtonHoverColor: '#96cff0',
				buttonBackgroundColor: '#2778bc',
				attributes: [
					{
						'Salesforce.memberid': ''
					},
					{
						dateOfBirth: ''
					},
					{
						email: ''
					},
					{
						name: ''
					}
				],
				miniForm: true,
				formData: [
					{
						type: 'text',
						formType: 'both',
						required: false,
						label: 'Preferred Name',
						cav: 'Name'
					}
				],
				miniSubmitColor: '#ffffff',
				miniSubmitColorCompleted: '#000000',
				timezone: 'America/Chicago',
				miniSubmitBackgroundColorCompleted: '#ffffff',
				fontChat: 'Roboto, serif',
				fontForm: 'Roboto, serif',
				fontFormInput: 'Roboto, serif',
				fontMenu: 'Roboto, serif',
				fontTitle: 'Roboto Condensed, serif',
				fontTemplate: 'Roboto, serif',
				styleSheets: [
					'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
					`${window.location.origin}/five9Styles.css`
				]
			}
		}
	},
	mounted() {
		//Initiate process
		this.selectedPlan = useSelectedPlan()
		this.gainAccess()
		console.log(this.options)
	},
	methods: {
		chatCallback(event) {
			try {
				switch (event.type) {
					case 'accepted':
						console.log(`Chat has been accepted by ${event.agentDisplayName}`)
						document.querySelector('body').classList.add('chat_started')
						break
					case 'endClicked':
						console.log('End button was clicked')
						break
					case 'endConfirmation':
						console.log('End Confirmation should be displayed by consumer...')
						break
					case 'endChatConfirmed':
						console.log('End Chat Confirmed')
						break
					case 'endChatCancelled':
						console.log('End Chat Cancelled')
						break
					case 'terminate':
						window.dispatchEvent(chatEnded)
						document.querySelector('body').classList.remove('chat_started')
						console.log(`Chat terminated`)
						break
					default:
						break
				}
			} catch (exception) {
				console.error('Exception caught in event handler: ' + exception.stack)
			}
		},
		async patientInformation() {
			await retrievePatientData().then((res) => {
				setTimeout(() => {
					const nameIndex = this.options.attributes.findIndex((el) => el.name != undefined)
					const emailIndex = this.options.attributes.findIndex((el) => el.email != undefined)
					const dobIndex = this.options.attributes.findIndex((el) => el.dateOfBirth != undefined)
					const salesForceIndex = this.options.attributes.findIndex(
						(el) => el['Salesforce.memberid'] != undefined
					)

					const { name, birthDate, telecom } = res.patientData[this.selectedPlan.memberId]

					const email = telecom.filter((tele) => tele.system === 'email')[0].value
					this.options.attributes[nameIndex].name = name[0]['given'][0]
					this.options.attributes[emailIndex].email = email
					this.options.attributes[dobIndex].dateOfBirth = birthDate
					this.options.attributes[salesForceIndex]['Salesforce.memberid'] =
						this.selectedPlan.memberId
					return
				}, 10)
			})
		},
		async gainAccess() {
			//Get current access rights
			await retrieveAccessRights()
				.then(async (res) => {
					if (res.accessRights.chatType === 'Five9') {
						await this.patientInformation()
						return true
					} else {
						return false
					}
				})
				.then((showChat) => {
					if (showChat) {
						this.options.callback = this.chatCallback
						Five9ChatPlugin(this.options)
					}
				})
		}
	}
}
</script>
